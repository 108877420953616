import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgClass, NgIf } from "@angular/common";
import { faImage } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'kb-picture',
    template: `
        <picture *ngIf="src && !loadError; else imagePlaceholder">
            <source [srcset]="srcURL.toString() + '&format=avif'" type="image/avif" />
            <source [srcset]="srcURL.toString() + '&format=webp'" type="image/webp" />
            <img
                (load)="imageLoad.emit($event)"
                (error)="loadError = true"
                class="mx-auto"
                [ngClass]="imgClass"
                [src]="srcURL.toString()"
                [alt]="alt"
                [attr.width]="w ?? null"
                [attr.height]="h ?? null"
                [attr.loading]="lazy ? 'lazy' : 'eager'"
            />
        </picture>
        <ng-template #imagePlaceholder>
            <div class="flex items-center justify-center">
                <fa-icon [icon]="image" [size]="'4x'" class="text-gray-300"></fa-icon>
            </div>
        </ng-template>
    `,
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        FontAwesomeModule,
    ],
})
export class PictureComponent implements OnChanges {
    @Input() src: string;
    @Input() alt: string;
    @Input() preset: 'tiny' | 'medium' | 'thumb' | 'large';
    @Input() w: number;
    @Input() h: number;
    @Input() focalPoint: { x: number; y: number } | undefined;
    @Input() imgClass = '';
    @Input() lazy = false;
    @Output() imageLoad = new EventEmitter<Event>();
    srcURL: URL;
    loadError = false;
    image = faImage;

    ngOnChanges() {
        this.buildQueryString();
    }

    private buildQueryString() {
        if (!this.src) {
            return;
        }
        try {
            this.srcURL = new URL(this.src);
            if (this.w) {
                this.srcURL.searchParams.set('w', this.w.toString());
            }
            if (this.h) {
                this.srcURL.searchParams.set('h', this.h.toString());
            }
            if (this.preset) {
                this.srcURL.searchParams.set('preset', this.preset);
            }
            if (this.focalPoint) {
                this.srcURL.searchParams.set('fpx', this.focalPoint.x.toString());
                this.srcURL.searchParams.set('fpy', this.focalPoint.y.toString());
            }
            this.srcURL.searchParams.set('mode', 'crop');
        } catch (e) {}
    }
}
